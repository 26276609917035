<template>
  <v-app class="admiAppMain">
    <v-main>
      <router-view></router-view>
      <router-view name="user-account"></router-view>
    </v-main>
  </v-app>
</template>
<script>
export default {
  computed: {
    appLoaded() {
      return this.$store.state.appLoaded;
    },
  },
};
</script>
<style scoped>
.admiAppMain {
  background-color: #0c4da2;
}
</style>