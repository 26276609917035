<template>
  <v-app-bar app color="primary" dark>
    <div class="d-flex align-center">
      <v-img
        v-show="srcLog"
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        :src="srcLog"
        transition="scale-transition"
        width="40"
      />
      <h2>{{ companyAlias }}</h2>
    </div>
    <v-spacer></v-spacer>
    <strong>SAC</strong>
  </v-app-bar>
</template>
<script>
export default {
  data() {
    return {};
  },

  computed: {
    companyAlias() {
      return this.$store.state.sac.webPageInfoLayout.companyAlias;
    },

    srcLog() {
			if(!process.env.VUE_APP_API_URL || !this.$store.state.sac.webPageInfoLayout.logo || !this.integrationKey){
				return ""
			}
			
      return `${process.env.VUE_APP_API_URL}${this.$store.state.sac.webPageInfoLayout.logo}?integrationKey=${this.integrationKey}`;
    },

    integrationKey() {
      return this.$store.state.integrationKey;
    },
  },
};
</script>