<template>
  <component :is="appComponent">
    <v-global-error></v-global-error>
  </component>
</template>

<script>
import VSacAppMain from "@/components/VSacAppBase/VSacAppMain";
import VAdminAppMain from "@/components/VAdminAppBase/VAdminAppMain";
import vGlobalError from "@/components/VGlobalError/VGlobalError";

export default {
  name: "App",

  components: {
    VAdminAppMain,
    VSacAppMain,
    vGlobalError,
  },

  computed: {
    currentConfiguredApp() {
      return this.$store.state.currentConfiguredApp;
    },

    appComponent() {
      if (this.currentConfiguredApp === "sac") {
        return "v-sac-app-main";
      }

      return "v-admin-app-main";
    },
  },
};
</script>
