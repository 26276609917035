import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "./router"
import store from "./store"
import VueTheMask from 'vue-the-mask'
import VueCookie from 'vue-cookie'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'  //Globally import VTextField

Vue.component('v-text-field', VTextField)

Vue.use(VCurrencyField, { 
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: false
})

Vue.use(VueTheMask)
Vue.use(VueCookie)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  VueCookie,
  render: h => h(App)
}).$mount('#app')
