<template>
  <v-snackbar v-model="snackbar" multi-line top :timeout="60000">
    <v-flex md2>
      <strong>Ops!</strong>
    </v-flex>
    <v-flex v-if="globalError" md12>
      Houve um erro ao tentar processar sua solicitação. Tente novamente mais tarde.
    </v-flex>
    <v-btn color="pink" text @click="snackbar = false"> Fechar </v-btn>
  </v-snackbar>
</template>
<script>
export default {
  data() {
    return {
      snackbar: false,
    };
  },
  computed: {
    globalError() {
      return this.$store.state.error;
    },
  },
  watch: {
    globalError(value) {
      if (value) {
        this.snackbar = true;
      }
    },
  },
};
</script>