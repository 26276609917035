import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: { pt },
		current: 'pt',
	},
	icons: {
		iconfont: 'mdi',
	},
	theme: {
		themes: {
			light: {
				primary: "#0c4da2",
				secondary: "#0091D0",
			}
		}
	},
});
