<template>
  <div class="pl-5 pt-3">
    <v-row no-gutters>
      <v-col cols="12" class="pt-1 mt-0">
        <slot></slot>
      </v-col>
      <v-col cols="10" class="pa-0 ma-0">
        <v-row no-gutters class="ma-0 pa-0">
          <v-flex
            md4
            class="ma-0 mr-0 pa-1"
            v-for="company in companies"
            :key="company.id"
          >
            <v-card class="pa-5 primary darken-1" height="100%">
              <v-col class="ma-0 pa-0" cols="12">
                <v-row>
                  <v-col class="pt-5" cols="1"
                    ><v-icon color="red" small>mdi-map-marker</v-icon></v-col
                  >
                  <v-col>
                    <span>
                      {{ company.address }}, {{ company.addressnumber }}, {{ company.neighborhood }}
                    </span>
                    <br />
                    <span>
                      {{ company.city }} /
                      {{ company.state }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="ma-0 pa-0" cols="12">
                <v-row class="pt-0">
                  <v-col class="mt-0 pt-0" cols="1"
                    ><v-icon small>mdi-phone</v-icon></v-col
                  >
                  <v-col class="mt-0 pt-0">
                    <span>{{ company.phone1 }}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-flex>
        </v-row>
      </v-col>
      <v-col cols="12" class="primary pt-10 pr-5 text-center">
        <v-software-copyright></v-software-copyright>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import VSoftwareCopyright from "@/components/VSoftwareFooterInfo/VSoftwareCopyright";

export default {
  components: {
    VSoftwareCopyright,
  },
  data() {
    return {};
  },
  computed: {
    companies() {
      return this.$store.state.sac.companies;
    },
  },
};
</script>