<template>
  <v-row justify="center" no-gutters>
    <v-col class="pl-5 pt-3">
      <slot></slot>
    </v-col>
    <v-col class="text-center pt-5"> </v-col>
    <v-col dark class="primary py-4 pr-5 text-end white--text">
      <v-software-copyright></v-software-copyright>
    </v-col>
  </v-row>
</template>
<script>
import VSoftwareCopyright from "./VSoftwareCopyright";

export default {
  components: {
    VSoftwareCopyright,
  },
};
</script>
