const sac = {
    namespaced: true,

    state: {
        integrationKey: "",
        companies: [],
        integrationKeyValid: true,
        issues: [],
        customerData: null,
        customerNotFound: false,
        instructionsForInspection: [],
        webPageInfoLayout: {},
    },

    mutations: {
        setIntegrationKey(state, payload) {
            state.integrationKey = payload
        },

        setCompanies(state, payload) {
            state.companies = payload
        },

        setIntegrationKeyValid(state, payload) {
            state.integrationKeyValid = payload
        },

        setCompanyName(state, payload) {
            state.companyName = payload
        },

        setCustomerData(state, payload) {
            state.customerData = payload
        },

        setCustomerNotFound(state, payload) {
            state.customerNotFound = payload
        },

        setCustomerSelectedOrderItem(state, payload) {
            state.customerData.selectedOrderItem = payload
        },

        setCustomerIssuesSelected(state, payload) {
            state.customerData.selectedIssues = payload
        },

        setInstructionForInspection(state, payload) {
            state.instructionsForInspection = payload
        },

        setIssues(state, payload) {
            state.issues = payload
        },

        setWebPageInfoLayout(state, payload) {
            state.webPageInfoLayout = payload
        }
    },

    actions: {
        changeIntegrationKey({ commit }, integrationKey) {
            commit("setIntegrationKey", integrationKey)
        },

        async loadCompanies({ commit }, integrationKey) {
            let response = await fetch(
                `${process.env.VUE_APP_API_URL}/api/v1/sac/companies?integrationKey=${integrationKey}`
            );

            let body = await response.json()

            commit("setCompanies", body ? body : [])
        },

        changeIntegrationKeyToInvalid({ commit }) {
            commit("setIntegrationKeyValid", false)
        },

        changeIntegrationKeyToValid({ commit }) {
            commit("setIntegrationKeyValid", true)
        },

        async loadCustomerData({ commit, state }, document) {
            let response = await fetch(`${process.env.VUE_APP_API_URL}/api/v1/sac/customers/${document}?integrationKey=${state.integrationKey}`)

            if (response.status != 200) {
                commit("setCustomerData", null)
                commit("setCustomerNotFound", true)
                return
            }

            let body = await response.json()

            commit("setCustomerData", body)
            commit("setCustomerNotFound", false)
        },

        changeCustomerSelectedOrderItem({ commit }, item) {
            commit("setCustomerSelectedOrderItem", { ...item })
        },

        changeCustomerIssuesSelected({ commit }, list) {
            commit("setCustomerIssuesSelected", [...list])
        },

        async loadInstrunctionForInspection({ commit, state }) {
            if (state.instructionsForInspection.length > 0) return

            let response = await fetch(
                `${process.env.VUE_APP_API_URL}/api/v1/sac/web-instructions?integrationKey=${state.integrationKey}`
            );

            let body = await response.json();

            commit("setInstructionForInspection", body)
        },

        async loadIssues({ commit, state }) {
            if (state.issues.length > 0) return

            let response = await fetch(
                `${process.env.VUE_APP_API_URL}/api/v1/sac/issues?integrationKey=${state.integrationKey}`
            );

            let issues = await response.json();

            commit("setIssues", issues)
        },

        async setupAppSac({ dispatch, state }) {
            await dispatch("loadWebPageInfoLayout")

            if (state.integrationKeyValid) {
                await dispatch("setupApp", "sac", { root: true })
            }
        },

        async loadWebPageInfoLayout({ commit, state, dispatch }) {
            let response = await fetch(
                `${process.env.VUE_APP_API_URL}/api/v1/sac/web-layout-info?integrationKey=${state.integrationKey}`
            );

            if (response.status != 200) {
                commit("setWebPageInfoLayout", {})
                dispatch("changeIntegrationKeyToInvalid")
            }

            let body = await response.json();

            commit("setWebPageInfoLayout", body)
        }
    }
}

export default sac