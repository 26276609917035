<template>
  <v-footer
    dark
    class="rounded-lg rounded-b-0 mx-2 mx-sm-10 elevation-10"
    color="primary"
    v-if="integrationKeyValid"
  >
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0">
        <v-expand-transition>
          <v-software-info-footer v-show="!showCompanies">
            <v-btn @click="viewCompanies" text>
              Nossas lojas
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
          </v-software-info-footer>
        </v-expand-transition>
        <v-expand-transition>
          <v-companies-list-footer v-show="showCompanies">
            <v-btn @click="hideCompanies" text>
              Nossas lojas
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </v-companies-list-footer>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import VSoftwareInfoFooter from "@/components/VSoftwareFooterInfo/VSoftwareFooterInfo";
import VCompaniesListFooter from "@/components/VCompaniesListFooter/VCompaniesListFooter";

export default {
  data() {
    return {
      showCompanies: false,
    };
  },

  computed: {
    pageHeight() {
      return document.body.scrollHeight;
    },

    companies() {
      return this.$store.state.sac.companies;
    },

    integrationKey() {
      return this.$store.state.sac.integrationKey;
    },

    integrationKeyValid() {
      return this.$store.state.sac.integrationKeyValid;
    },
  },

  methods: {
    async viewCompanies() {
      if (this.companies.length == 0) {
        await this.$store.dispatch("sac/loadCompanies", this.integrationKey);
      }

      this.showCompanies = true;
      this.$vuetify.goTo(this.pageHeight);
    },

    hideCompanies() {
      this.showCompanies = false;
    },
  },

  components: {
    VSoftwareInfoFooter,
    VCompaniesListFooter,
  },
};
</script>