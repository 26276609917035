import Vuex from "vuex"
import Vue from "vue"
import sac from "./sac"

Vue.use(Vuex)

export default new Vuex.Store({
    namespaced: true,
    state: {
        appLoaded: false,
        currentConfiguredApp: "default",
        error: null,
        user: {},
    },
    mutations: {
        setAppLoaded(state, payload) {
            state.appLoaded = payload
        },

        setCurrentConfiguredApp(state, payload) {
            state.currentConfiguredApp = payload
        },

        setError(state, payload) {
            state.error = payload
        },

        setUser(state, payload) {
            state.user = payload
        }
    },
    actions: {
        completeLoadApp({ commit }) {
            commit("setAppLoaded", true)
        },

        setupApp({ commit }, app) {
            commit("setCurrentConfiguredApp", app)
        },

        handlerError({ commit }, error) {
            commit("setError", error)
        },

        login({ commit }, user) {
            commit("setUser", user)
        },

        logout({ commit }){
            Vue.cookie.delete("currentUser")
            commit("setUser", {})
        }
    },
    modules: {
        sac,
    }
})