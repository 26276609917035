<template>
  <v-app v-show="appLoaded">
    <slot></slot>
    <v-sac-app-bar></v-sac-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-sac-app-footer></v-sac-app-footer>
  </v-app>
</template>
<script>
import VSacAppBar from "@/components/VSacAppBase/VSacAppBar";
import VSacAppFooter from "@/components/VSacAppBase/VSacAppFooter";

export default {
  components: {
    VSacAppBar,
    VSacAppFooter,
  },

  computed: {
    appLoaded() {
      return this.$store.state.appLoaded;
    },
  },
};
</script>