import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/pages/VHome.vue")
    },
    {
        path: "/parceiros/minha-conta/produtos",
        name: "partnership-products",
        components: {
            default: () => import("@/pages/VPartnershipAccount.vue"),
            "user-account": () => import("@/pages/VPartnershipProviderProduct.vue"),
        },
        beforeEnter: (to, from, next) => {
            let user = Vue.cookie.get("currentUser")

            if (user) {
                store.state.user = JSON.parse(user)
            }

            if (store.state.user.name) {
                next()
            } else {
                next({ name: "partnership-login" })
            }
        }
    },
    {
        path: "/parceiros/minha-conta/pedidos",
        name: "partnership-purchase-orders",
        components: {
            default: () => import("@/pages/VPartnershipAccount.vue"),
            "user-account": () => import("@/pages/PartnershipPurchaseOrders.vue"),
        },
        beforeEnter: (to, from, next) => {
            let user = Vue.cookie.get("currentUser")

            if (user) {
                store.state.user = JSON.parse(user)
            }

            if (store.state.user.name) {
                next()
            } else {
                next({ name: "partnership-login" })
            }
        }
    },
    {
        path: "/parceiros/minha-conta/pedidos/novo",
        name: "partnership-purchase-orders-new",
        components: {
            default: () => import("@/pages/VPartnershipAccount.vue"),
            "user-account": () => import("@/pages/PartnershipPurchaseOrdersNew.vue"),
        },
        beforeEnter: (to, from, next) => {
            let user = Vue.cookie.get("currentUser")

            if (user) {
                store.state.user = JSON.parse(user)
            }

            if (store.state.user.name) {
                next()
            } else {
                next({ name: "partnership-login" })
            }
        }
    },
    {
        path: "/parceiros/minha-conta/pedidos/:id",
        name: "partnership-purchase-orders-edit",
        components: {
            default: () => import("@/pages/VPartnershipAccount.vue"),
            "user-account": () => import("@/pages/PartnershipPurchaseOrdersEdit.vue"),
        },
        beforeEnter: (to, from, next) => {
            let user = Vue.cookie.get("currentUser")

            if (user) {
                store.state.user = JSON.parse(user)
            }

            if (store.state.user.name) {
                next()
            } else {
                next({ name: "partnership-login" })
            }
        }
    },
    {
        path: "/parceiros/minha-conta",
        name: "partnership-account",
        components: {
            default: () => import("@/pages/VPartnershipAccount.vue"),
        },
        beforeEnter: (to, from, next) => {
            let user = Vue.cookie.get("currentUser");

            if (user) {
                store.state.user = JSON.parse(user)
            }

            if (store.state.user.name) {
                next()
            } else {
                next({ name: "partnership-login" })
            }
        }
    },
    {
        path: "/parceiros/minha-conta/gerenciar-usuarios",
        name: "partnership-account-user-management",
        components: {
            default: () => import("@/pages/VPartnershipAccount.vue"),
            "user-account": () => import("@/pages/VPartnershipUsersManagement.vue"),
        },
        beforeEnter: (to, from, next) => {
            let user = Vue.cookie.get("currentUser")

            if (user) {
                store.state.user = JSON.parse(user)
            }

            if (store.state.user.name) {
                next()
            } else {
                next({ name: "partnership-login" })
            }
        }
    },
    {
        path: "/parceiros/minha-conta/powerbi",
        name: "powerbi",
        components: {
            "user-account": () => import("@/pages/VPowerBIFrame.vue"),
        },
        beforeEnter: (to, from, next) => {
            let user = Vue.cookie.get("currentUser")

            if (user) {
                store.state.user = JSON.parse(user)
            }

            if (store.state.user.name) {
                next()
            } else {
                next({ name: "partnership-login" })
            }
        }
    },
    {
        path: "/parceiros/novo",
        name: "partnership-user-create",
        component: () => import("@/pages/VPartnershipProviderUserCreate.vue")
    },
    {
        path: "/parceiros/login",
        name: "partnership-login",
        component: () => import("@/pages/VPartnershipLogin.vue")
    },
    {
        path: "/sac",
        name: "sac",
        component: () => import("@/pages/VSacHome.vue"),
    },
    {
        path: "/sac/agendar-vistoria/",
        name: "sac-inspection",
        component: () => import("@/pages/VSacOrderInspection.vue"),
        beforeEnter: (to, from, next) => {
            if (store.state.sac.integrationKey) {
                next()
            } else {
                next({ name: "home" })
            }
        }
    },
    {
        path: "/sac/protocolo",
        name: "sac-protocol",
        props: true,
        component: () => import("@/pages/VSacProtocol.vue"),
        beforeEnter: (to, from, next) => {
            if (store.state.sac.integrationKey) {
                next()
            } else {
                next({ name: "home" })
            }
        }
    },
    {
        path: '*',
        component: () => import("@/pages/VHome.vue"),
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes
})

export default router